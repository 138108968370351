import Google from "../img/google.png";
import Facebook from "../img/facebook.png";
import Github from "../img/github.png";
import { useState } from "react";
import TopBar from "../components/TopBar";
import '../styles/TopBar.css';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {

  const { loginWithRedirect } = useAuth0();
    const [loginMsg, setLoginMsg] = useState("");

    const google = () => {
        loginWithRedirect({connection: 'google-oauth2',
                           appState: { targetUrl: '/' }
        });
        // window.open("http://localhost:5000/auth/google", "_self");
        console.log("google");
    };

    const github = () => {
        // window.open("http://localhost:5000/auth/github", "_self");
        console.log("github");
    };

    const facebook = () => {
        // window.open("http://localhost:5000/auth/facebook", "_self");
        console.log("facebook");
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        let username = event.target.username.value;
        // if username does not end with @ailight.com, add the postfix
        if (!username.endsWith('@ailight.com')) {
            username = username + '@ailight.com';
        }
        const password = event.target.password.value;

        try {
        await loginWithRedirect({
            authorizationParams: {
            redirectUri: window.location.origin,
            email: username,
            password: password,
            connection: "Username-Password-Authentication",
            },
            appState: {
            targetUrl: window.location.href
            },
        });
        } catch (error) {
        console.error('Login failed', error);
        }
  };
    return (
        <div className="login">
            <TopBar className='fixed-bar'/>
            <h1 className="login-title">Choose a Login Method</h1>
            <div className="wrapper">
                <div className="left">
                    <div className="login-button google" onClick={google}>
                        <img src={Google} alt="" className="icon" />
                        Google
                    </div>
                    <div className="login-button facebook" onClick={facebook}>
                        <img src={Facebook} alt="" className="icon" />
                        Facebook
                    </div>
                    <div className="login-button github" onClick={github}>
                        <img src={Github} alt="" className="icon" />
                        Github
                    </div>
                </div>
                <div className="center">
                    <div className="line" />
                    <div className="or">OR</div>
                </div>
                <div className="right">
                    <form onSubmit={handleSubmit} className="login-form">
                        <div className="login-input-wrapper">
                            <input type="text" name="username" placeholder="Username" />
                            <input type="password" name="password" placeholder="Password" />
                        </div>
                        <button className="submit" type="submit">Login</button>
                        <p className="login-msg">{loginMsg}</p>
                    </form>
                </div>
            </div>
  <footer>
        <div className="footer-content">
            <p>&copy; 2024 AILight. All rights reserved.</p>
            <p><a href="/AI-Light-Platform-Terms-of-Service.pdf" target="_blank">Terms and Conditions</a></p>
        </div>
    </footer>
        </div>
    );
};

export default Login;
