import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import './app.css';
import Home from "./pages/Home";
import Login from "./pages/Login";
import KnownFakesPage from "./pages/KnownFakes";
import WhatsappMessageSender from "./pages/WhatsappBroadcast";
import store from "./store/store";
import Modal from "./components/Modal/Modal";

const internalAilightMails = ['guy@ailight.tech', 'morin@ailight.tech', 'justas@ailight.tech',
                              'nadav.ziv@ailight.tech', 'yuval.friedman@ailight.tech'];

const AuthenticatedRoute = ({ setAppUser, requiredRoles, children }) => {
  const { isAuthenticated, isLoading, error, user, getIdTokenClaims, logout } = useAuth0();
  const [userRoles, setUserRoles] = useState([]);
  const [rolesLoading, setRolesLoading] = useState(true);

  useEffect(() => {
    const getUserRoles = async () => {
      if (isAuthenticated) {
        try {
          // const claims = await getIdTokenClaims();
          // const roles = claims['https://dev-rt1gs3l7ub082gs0.us.auth0.com/roles'] || []; // TODO - make it work
          const roles = internalAilightMails.includes(user.email) ? ['admin'] : ['user'];
          setUserRoles(roles);
          setAppUser(user);
        } catch (error) {
          console.error('Error fetching user roles:', error);
        } finally {
          setRolesLoading(false); // Finished loading roles
        }
      } else {
        setUserRoles([]);
        setAppUser(null);
        setRolesLoading(false); // reset loading roles
      }
    };

    getUserRoles();
  }, [isAuthenticated, user, isLoading, error, getIdTokenClaims]);

  if (error) {
    logout(); // Log out and reset Auth0 session
    return <Navigate to="/login" />;
  }
  if (isLoading || rolesLoading) return <div>Loading...</div>;

  const hasRequiredRole = requiredRoles ? requiredRoles.some(role => userRoles.includes(role)) : true;

  return isAuthenticated && hasRequiredRole ? children : <Navigate to="/login" />;
};

const App = () => {
  const [appUser, setAppUser] = useState(null);

  /*TODO-fix hard-coded config into .env file*/
  return (
    <Auth0Provider
      domain="dev-rt1gs3l7ub082gs0.us.auth0.com"
      clientId="PsarWSl27RABfDzFev41PlGyobth4IDr"
      redirectUri={window.location.origin}
    >
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<AuthenticatedRoute setAppUser={setAppUser} requiredRoles={undefined}> <Home user={appUser}/></AuthenticatedRoute>} />
              <Route path="/fakedb" element={<AuthenticatedRoute setAppUser={setAppUser} requiredRoles={['admin']}> <KnownFakesPage/></AuthenticatedRoute>} />
              <Route path="/whatsapp-util" element={<AuthenticatedRoute setAppUser={setAppUser} requiredRoles={['admin']}> <WhatsappMessageSender/></AuthenticatedRoute>} />
            </Routes>
          </div>
          <Modal />
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  );
};

export default App;